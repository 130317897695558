import React from "react"
import BackgroundImage from 'gatsby-background-image'
import "./hero.css"

class newhero extends React.Component {
    render() {
      return(
        <BackgroundImage
          Tag="section"
          className={`bgimg-home bg-overlay-black-4`}
          fluid={this.props.heroImage}
          backgroundColor={`#040e18`} >
          <div className="article-caption-home">
            <div className="article-text">
              <span className="article-border-head">Hi, I like websites<br /><span className="article-border-sub">Sometimes I make them too!</span></span>
            </div>
          </div>
        </BackgroundImage>
      )
    }
}

export default newhero 